<template>
	<div class="h-100">
		<OrderFormWeb
			:id="id"
			:is-update="isUpdate"
			:order-detail="orderDetail"
			@submit="submit"
			@back="back"
		/>
		<CustomModal
			v-if="isLocked"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="confirm"
			@cancel-modal="confirm"
		>
			<template #content>
				{{ lockError }}
			</template>
		</CustomModal>
	</div>
</template>
<script>
import { createNamespacedHelpers } from "vuex"
import { GET_ORDER, LOCK_USER, UNLOCK_USER, UPDATE_OEDER_WEB } from "../store/action-types"
import { RESET_ORDER_FILTERS } from "../store/mutation-types"
const { mapActions, mapMutations } = createNamespacedHelpers("orders")
export default {
	name: "UpdateOrderWeb",
	components: {
		OrderFormWeb: () => import("../components/OrderFormWeb"),
	},
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
		id: {
			type: [Number, String],
			default: null,
		},
		originalId: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			orderDetail: null,
			lock: null,
			isLocked: false,
		}
	},
	async created() {
		await this.lockUser()
		if (!this.lockError) {
			this.lock = setInterval(async () => {
				await this.lockUser()
			}, process.env.VUE_APP_INTERVAL_LOCK)
			await this.getOrderDetail()
		} else {
			this.isLocked = true
			this.$store.commit("set", ["modalShow", true])
		}
	},
	mounted() {
		const body = document.getElementsByTagName("body")[0]
		body.scrollTop = 0
	},
	async beforeDestroy() {
		this.RESET_ORDER_FILTERS()
		await this.unlockUser()
		clearInterval(this.lock)
		this.isLocked = false
	},
	methods: {
		...mapActions({ GET_ORDER, LOCK_USER, UNLOCK_USER, UPDATE_OEDER_WEB }),
		...mapMutations({ RESET_ORDER_FILTERS }),
		async getOrderDetail() {
			const params = {
				data: {
					orderId: this.id,
					originalId: this.originalId,
				},
				notLoading: false,
			}
			const res = await this.GET_ORDER(params)
			this.orderDetail = res
		},
		async lockUser() {
			const params = {
				updateOrderId: this.id,
			}
			await this.LOCK_USER(params)
		},
		async unlockUser() {
			if (!this.isLocked) {
				const params = {
					updateOrderId: this.id,
				}
				await this.UNLOCK_USER(params)
			}
		},
		async submit(params) {
			this.showLoading()
			if (params.resultFiles && params.resultFiles.length) {
				const resultFiles = params.resultFiles.map(el => el.file)
				// add new file
				await this.$store.dispatch("uploadFile", resultFiles).then(res => {
					if (res.ok) {
						if (params.thumbnail) {
							const thumbnailIndex = params.resultFiles.findIndex(
								el => el.fileId === params.thumbnail.fileId,
							)
							delete params.thumbnail
							params["thumbnailResultToken"] = res.tokens[thumbnailIndex]
						}
						delete params.resultFiles
						params["resultUploadedTokens"] = res.tokens
					}
				})
			}

			await this.UPDATE_OEDER_WEB(params)
			this.hideLoading()
		},
		back() {
			this.$router.go(-1)
		},
		confirm() {
			this.$store.commit("set", ["modalShow", false])
			this.$router.go(-1)
		},
	},
}
</script>
<style></style>
